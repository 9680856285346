<template>
    <div class="page-tech-info g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>技术资料</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="tech-info">
                <div class="search">
                    <el-input v-model="searchKey" placeholder="搜索"></el-input>
                    <div class="btn" @click="getarticleTechnicalList">搜索</div>
                </div>
                <div class="tech-list">
                    <div class="item cursorPointer" @click="goTechnicadetail(item)" v-for="(item,index) in echnicalList" :key="index">
                        <a>《{{ item.title }}》</a>
                        <div class="date">{{ item.publishTime }}</div>
                    </div>

                </div>
                <div class="custom-pages">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        @prev-click="handlePrevChange"
                        @next-click="handleNextChange"
                        :current-page="current"
                        :page-sizes="[10, 50, 100]"
                        :page-size="size"
                        layout="total,  prev, pager, next,sizes, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {articleTechnicalPage} from "@/api/home";

export default {
    name: "techInfo",
    data() {
        return {
            searchKey: '',

            current:1,
            size:10,
            total:0,
            echnicalList:[],
        }
    },
    mounted() {
        this.getarticleTechnicalList() //技术资料

    },
    methods:{
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getarticleTechnicalList();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getarticleTechnicalList();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getarticleTechnicalList();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getarticleTechnicalList();
        },
        goTechnicadetail(item){
            // query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
            this.$router.push({path:'/TechInfoDet',query: {id:item.id}})
        },

        getarticleTechnicalList(){
            let param={
                current:this.current,
                size:this.size,
                title:this.searchKey
            }
            articleTechnicalPage(param).then((res) => { // isHot=1表示推荐
                if (res.data.code==200) {
                    res.data.data.records.forEach((i,index)=>{
                        i.publishTime=this.$utils.formatDate(i.publishTime,'yyyy-mm-dd')

                    })
                    this.echnicalList=res.data.data.records
                    this.total=Number(res.data.data.total)

                }

            })
        },
    },

}
</script>

<style scoped>

</style>
